import React from "react";
import {
  MDBRow,
  MDBContainer,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBIcon,
  MDBBtn,
} from "mdbreact";
import { Link } from "gatsby";
import { site } from "../data/site.data";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import { Helmet } from "react-helmet";
import image from "../images/grynn-hosting-og.png";

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Hosting - {site.data.companyName}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta property="og:url" content="https://signup.grynn.ch/" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Get ERPNext Hosting - Grynn GMBH" />
        <meta
          property="og:description"
          content="Get Free 90 Days Hosting for ERPNext by Grynn GMBH"
        />
        <meta property="og:image" content={image} />
      </Helmet>
      <Header />
      <MDBContainer>
        <section className="text-center my-5">
          <h2
            className="h1-responsive font-weight-bold text-center my-5"
            style={{
              color: "#525050",
            }}
          >
            {site.data.heading}
          </h2>
          <p className="grey-text text-center w-responsive mx-auto mb-5">
            {site.data.subTitle}
          </p>
          <MDBRow center>
            {site.data.plans.map(
              ({
                title,
                icon,
                iconColorClass,
                text,
                actionButtonLink,
                actionButtonText,
                price,
                currency,
                suggestedPlan,
              }) => (
                <MDBCol lg="4" md="12" className="mb-lg-0 mb-4">
                  <MDBCard className={suggestedPlan ? "indigo h-100" : "h-100"}>
                    <MDBCardBody className={suggestedPlan ? "white-text" : ""}>
                      <h5 className="mb-4">{title}</h5>
                      <div className="d-flex justify-content-center">
                        <div className="card-circle d-flex justify-content-center align-items-center">
                          <MDBIcon icon={icon} className={iconColorClass} />
                        </div>
                      </div>
                      <h2
                        style={{
                          fontSize: "2rem",
                          color: suggestedPlan ? "#ffffff" : "#525050",
                        }}
                        className="font-weight-bold my-4"
                      >{`${price} ${currency}`}</h2>
                      <p
                        style={{
                          color: suggestedPlan ? "#ffffff" : "#5d5d5d",
                        }}
                      >
                        {text}
                      </p>
                      <Link to={actionButtonLink}>
                        <MDBBtn
                          outline={suggestedPlan}
                          rounded
                          color={suggestedPlan ? "white" : "indigo"}
                        >
                          {actionButtonText}
                        </MDBBtn>
                      </Link>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
              )
            )}
          </MDBRow>
        </section>
      </MDBContainer>
      <MDBContainer>
        <MDBRow
          center
          className="text-center w-100 m-0 py-5 bg-dark white-text"
        >
          {site.data.validations.map(({ icon, value }) => (
            <MDBCol>
              <MDBIcon icon={`fa ${icon}`} className="white-text" size="4x" />
              <p className="grey-text">{value}</p>
            </MDBCol>
          ))}
        </MDBRow>
      </MDBContainer>
      <MDBContainer>
        <section className="my-5">
          <h2 className="black-text">Fragen und Antworten </h2>
          {site.data.qna.map(({ question, answer }) => (
            <div className="mt-4">
              <h4>{question}</h4>
              <p style={{ color: "#434744c9" }}>{answer} </p>
            </div>
          ))}
        </section>
      </MDBContainer>
      <Footer />
    </>
  );
};

export default IndexPage;
